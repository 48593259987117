import React from 'react'
import { Form, Segment, Header, Button, Table } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
import axios from 'axios'
import fecha from 'fecha'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'

import { dateFormat } from '../../utils/constants'
import {
  getLocaleMonths,
  getLocaleWeekLong,
  getLocaleWeekShort
} from '../../utils/calendar'

class PriceForm extends React.Component {
  state = {
    dateFrom: '',
    dateTo: '',
    adults: 2,
    errors: {},
    apiData: null
  }

  handleFormSubmit = () => {
    if (this.isFormValid) {
      const {
        i18n,
        hotelData: { htlCode, destCode }
      } = this.props
      const { dateFrom, dateTo, adults } = this.state

      const postUrl = process.env.TO_ONLINE_API_ENDPOINT
      const from = fecha.format(fecha.parse(dateFrom, dateFormat), 'YYYY-MM-DD')
      const to = fecha.format(fecha.parse(dateTo, dateFormat), 'YYYY-MM-DD')
      const lang = i18n.language

      this.setState({ loading: true })

      axios
        .post(postUrl, {
          SP_PRICING_AV_HOTEL: {
            IN_LANGCODE: lang,
            IN_DESTINATIONCODE: destCode,
            IN_PRICEDATEFROM: from,
            IN_PRICEDATETO: to,
            IN_NORMALOCCUPANCY: adults,
            IN_HOTELCODE: htlCode,
            IN_CURRENCY: 'CHF',

            IN_TOCODE: '',
            IN_ROOMCODE: '',
            IN_TOURBOMEALCODE: '',
            IN_CHDDOB1: '',
            IN_CHDDOB2: '',
            IN_CHDDOB3: '',
            IN_CHDDOB4: '',
            IN_IGNORE_XX: 0,
            IN_IGNORE_RQ: 0,
            IN_IGNORE_PRICE0: 1,
            IN_CURRENTDATE: '',
            IN_ROOMKEY: '',
            IN_HOTELKEY: '',
            IN_EXPORT_ONLY: 0
          }
        })
        .then(({ data }) => {
          this.setState({
            apiData: data.SP_PRICING_AV_HOTELResponse.rowset,
            errors: {},
            loading: false
          })
        })
        .catch(error => {
          this.setState(prev => ({
            apiData: null,
            errors: { ...prev.errors, api: error.message },
            loading: false
          }))
        })
    }
  }

  handleDateChange = (date, modifiers, dateInput) => {
    const name = dateInput.input.name
    const value = fecha.format(date, dateFormat)

    this.setState({ [name]: value })
  }

  handleDateCheck = name => {
    if (!this.state[name]) {
      this.setState(prev => ({ errors: { ...prev.errors, [name]: true } }))
    } else {
      this.setState(prev => ({ errors: { ...prev.errors, [name]: false } }))
    }
  }

  incrementAdult = () => {
    this.setState({ ...this.state, adults: this.state.adults + 1 })
  }

  decrementAdult = () => {
    this.setState({ ...this.state, adults: this.state.adults - 1 })
  }

  isFormValid = () => {
    const { dateFrom, dateTo, adults } = this.state
    const adultsValid = adults > 0

    return dateFrom && dateTo && adultsValid
  }

  render () {
    const { loading, dateFrom, dateTo, adults, errors, apiData } = this.state
    const { t } = this.props

    return (
      <Segment vertical basic padded>
        <div className='price-form'>
          <Segment color='red' inverted attached='top'>
            <Header as='h3' inverted>
              {t('priceForm.header')}
            </Header>
          </Segment>

          <div className='form-wrapper'>
            <Form onSubmit={this.submit}>
              <Form.Group widths='equal'>
                <Form.Field required width='6'>
                  <label>{t('priceForm.period')}</label>
                  <Form.Group widths='equal'>
                    <DayPickerInput
                      placeholder={t('priceForm.from')}
                      inputProps={{
                        name: 'dateFrom',
                        autoComplete: 'off',
                        className: `${errors.dateFrom ? 'error' : ''}`,
                        readOnly: true
                      }}
                      format={dateFormat}
                      formatDate={(date, format) => fecha.format(date, format)}
                      onDayChange={this.handleDateChange}
                      dayPickerProps={{
                        modifiers: {
                          disabled: { before: new Date() }
                        },
                        selectedDays: fecha.parse(dateFrom, dateFormat),
                        months: getLocaleMonths(),
                        weekdaysLong: getLocaleWeekLong(),
                        weekdaysShort: getLocaleWeekShort()
                      }}
                      onDayPickerHide={() => this.handleDateCheck('dateFrom')}
                    />
                    <DayPickerInput
                      placeholder={t('priceForm.to')}
                      inputProps={{
                        name: 'dateTo',
                        autoComplete: 'off',
                        className: `${errors.dateTo ? 'error' : ''}`,
                        readOnly: true
                      }}
                      format={dateFormat}
                      formatDate={(date, format) => fecha.format(date, format)}
                      onDayChange={this.handleDateChange}
                      dayPickerProps={{
                        modifiers: {
                          disabled: {
                            before: dateFrom
                              ? fecha.parse(dateFrom, dateFormat)
                              : new Date()
                          }
                        },
                        fromMonth: new Date(),
                        initialMonth:
                          dateTo || dateFrom
                            ? fecha.parse(dateTo || dateFrom, dateFormat)
                            : new Date(),
                        selectedDays: fecha.parse(dateTo, dateFormat),
                        months: getLocaleMonths(),
                        weekdaysLong: getLocaleWeekLong(),
                        weekdaysShort: getLocaleWeekShort()
                      }}
                      onDayPickerHide={() => this.handleDateCheck('dateTo')}
                    />
                  </Form.Group>
                </Form.Field>

                <Form.Field width='5'>
                  <label>{t('priceForm.adults')}</label>
                  <Form.Group widths='equal'>
                    <Form.Input
                      placeholder={t('priceForm.adults')}
                      name='adults'
                      // type='number'
                      value={adults}
                      fluid
                    />
                    <Button icon='plus' basic onClick={this.incrementAdult} />
                    <Button icon='minus' basic onClick={this.decrementAdult} />
                  </Form.Group>
                </Form.Field>

                <Form.Field width='5'>
                  <label style={{ visibility: 'hidden' }}>.</label>
                  <Button
                    loading={loading}
                    disabled={!this.isFormValid()}
                    floated='right'
                    color='red'
                    className='btn-submit'
                    onClick={this.handleFormSubmit}
                  >
                    {t('priceForm.submit')}
                  </Button>
                </Form.Field>
              </Form.Group>
            </Form>

            {apiData && (
              <div className='price-form-results'>
                {apiData.length ? (
                  <Table basic='very' selectable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>
                          {t('priceForm.room')}
                        </Table.HeaderCell>
                        <Table.HeaderCell className='result-price'>
                          {t('priceForm.price')}
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {apiData.map((row, idx) => {
                        return (
                          <Table.Row key={idx}>
                            <Table.Cell>
                              <div className='result-room'>{row.ROOMTYPE}</div>
                              <div className='result-additional'>
                                {row.MEALDESCRIPTION}
                              </div>
                            </Table.Cell>
                            <Table.Cell className='result-price'>
                              CHF&nbsp;{row.TOTAL}
                            </Table.Cell>
                          </Table.Row>
                        )
                      })}
                    </Table.Body>
                  </Table>
                ) : (
                  <div className='no-results'>{t('priceForm.noData')}</div>
                )}
              </div>
            )}

            {errors.api}
          </div>
        </div>
      </Segment>
    )
  }
}

export default withTranslation()(PriceForm)
